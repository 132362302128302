<template>
  <div id="manoeuvre">
    <el-row>
      <el-col :span="12">
        <div class="grid-content">
          <el-form :model="manoeuvreRequire" class="manoeuvre-form" ref="manoeuvreForm" label-width="120px">
            <el-form-item label="最大舵角">
              <el-input class="parent-ship-input" v-model="manoeuvreRequire.rudderAngle" placeholder="范围为 -35 ～ 35"></el-input>
            </el-form-item>
            <el-form-item label="模型">
              <el-select v-model="manoeuvreRequire.modelChoice" placeholder="请选择使用的模型">
                <el-option label="井上模型" value="1"></el-option>
                <el-option label="贵岛模型" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-button type="success" round @click="simulate" :disabled="!nextStepDisable">模拟回转</el-button>
            <el-button  round @click="clearDraw">清除</el-button>
          </el-form>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content">
          <ManoeuvreResult :axis-data="axisDatas" :angle="this.manoeuvreRequire.rudderAngle" ref="result"></ManoeuvreResult>
          <el-button type="primary" round @click="nextStep" :disabled="nextStepDisable">生成报告</el-button>
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import ManoeuvreResult from "@/components/ManoeuvreResult/ManoeuvreResult";

export default {
  name: "ManoeuvreSimulate",
  components: {
    ManoeuvreResult
  },
  data() {
    return {
      manoeuvreRequire: {
        rudderAngle: "",
        modelChoice: "",
      },
      nextStepDisable: true,
      axisDatas: [],
      axisData: {
        x: "",
        y: ""
      }
    }
  },
  methods: {
    nextStep: function () {
      this.$emit('anglePass', this.manoeuvreRequire.rudderAngle);
      this.$emit('newStepWithAxis', this.axisDatas);
    },
    clearDraw: function () {
      this.axisDatas = [];
      this.$refs.result.drawChart();
      this.nextStepDisable = true;
    },
    simulate: function () {
      var url = "https://api2.ikin.top/ship/design/manoeuvre";
      if(this.manoeuvreRequire.modelChoice === "" || this.manoeuvreRequire.rudderAngle === "") {
        this.$message({
          message: '参数缺失',
          type: 'warning',
          center: 'true'
        });
      }else if(this.manoeuvreRequire.rudderAngle > 35 || this.manoeuvreRequire.rudderAngle < -35) {
        this.$message({
          message: '最大舵角必须在-35～35的范围内',
          type: 'warning',
          center: 'true'
        });
      }else {
        this.$axios({
          method: "POST",
          url: url,
          params: {
            userId: sessionStorage.getItem("userId"),
            token: sessionStorage.getItem("token")
          },
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: JSON.stringify({
            rudderAngle: this.manoeuvreRequire.rudderAngle,
            modelChoice: this.manoeuvreRequire.modelChoice
          })
        }).then((response) => {
          if(response.data.success) {
            for(var i = 0; i < response.data.count; i ++) {
              this.axisData = response.data.data[i];
              this.axisDatas.push([this.axisData.x, this.axisData.y]);
            }
            this.$message({
              message: '提交成功，正在模拟计算 ',
              type: 'success',
              center: 'true'
            });
            setTimeout(() => {
              this.nextStepDisable = false;
              this.$refs.result.drawChart();
            }, 3000);
          }else if(response.data.code === 301 || response.data.code === 302){
            this.$message({
              message: '用户信息失效',
              type: 'warning',
              center: 'true'
            });
            sessionStorage.setItem("isLogin", 0);
            this.$router.push("/login");
          }else if(response.data.code === 606){
            this.$message({
              message: response.data.msg,
              type: 'warning',
              center: 'true'
            });
          } else {
            this.$message({
              message: '提交信息失败',
              type: 'warning',
              center: 'true'
            });
          }
        }).catch((error) => {
          this.$message({
            message: '服务器错误',
            type: 'error',
            center: 'true'
          });
          console.error(error);
        });
      }

    }
  },
}
</script>

<style scoped>
.grid-content {
  margin-top: 10vh;
}
</style>
