<template>
  <div class="mobile">
    <div class="mobile-warning">
      <i class="el-icon-warning"></i>
      <p>本系统暂未对移动端提供支援</p>
      <p>请使用桌面设备进行访问</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Mobile"
}
</script>

<style scoped>
.mobile-warning {
  position: relative;
  top: 30vh;

}

.el-icon-warning {
  font-size: 90px;
  color: red;
  margin-bottom: 20px;
}

p {
  font-family: "SF Pro SC","SF Pro Display","SF Pro Icons","PingFang SC","Helvetica Neue","Helvetica","Arial",sans-serif;
  font-size: 18px;
  margin: 10px;
}

</style>
