<template>
  <div id="user-center">
    <div class="user-center-box">

      <button class="user-center-btn" type="button" @click="$emit('CenterSwitch')">
        <i class="icon el-icon-s-marketing"></i>
      </button>

      <div class="function-box">
        <el-row class="function-tool-bar">
          <el-col :span="4">
            <div class="user-function" @click="activePassword">
              <i class="user-function-logo el-icon-lock"></i>
              <p class="user-function-text">修改密码</p>
            </div>
          </el-col>
          <el-col :span="4" v-show="isAdmin">
            <div class="user-function" @click="activeUser">
              <i class="user-function-logo el-icon-user"></i>
              <p class="user-function-text">用户管理</p>
            </div>
          </el-col>
          <el-col :span="4" v-show="isAdmin">
            <div class="user-function" @click="activeShip">
              <i class="user-function-logo el-icon-edit"></i>
              <p class="user-function-text">船舶管理</p>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="user-function" @click="activeAbout">
              <i class="user-function-logo el-icon-warning-outline"></i>
              <p class="user-function-text">关于</p>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="user-function-logout" @click="logout">
              <i class="user-function-logo el-icon-switch-button"></i>
              <p class="user-function-text">退出登陆</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="user-box">
      <PasswordManage v-if="activeFunction === 0"></PasswordManage>
      <UserManage v-else-if="activeFunction === 1"></UserManage>
      <ShipManage v-else-if="activeFunction === 2"></ShipManage>
      <About v-else-if="activeFunction === 3"></About>
    </div>
  </div>
</template>

<script>
import PasswordManage from "@/components/PasswordManage/PasswordManage";
import UserManage from "@/components/UserManage/UserManage";
import ShipManage from "@/components/ShipManage/ShipManage";
import About from "@/components/About/About";

export default {
  name: "UserCenter",
  components: {
    UserManage,
    PasswordManage,
    ShipManage,
    About
  },
  data() {
    return {
      isAdmin: 0,
      activeFunction: 0
    }
  },
  methods: {
    activePassword: function () {
      this.activeFunction = 0;
    },
    activeUser: function () {
      this.activeFunction = 1;
    },
    activeShip: function () {
      this.activeFunction = 2;
    },
    activeAbout: function () {
      this.activeFunction = 3;
    },
    logout: function () {
      var url = "https://api2.ikin.top/user/logout";
      this.$axios({
        method: 'get',
        url: url,
        params: {
          userId: sessionStorage.getItem("userId"),
          token: sessionStorage.getItem("token")
        }
      }).then((response) => {
        if (response.data.success) {
          sessionStorage.clear();
          this.$router.push("/");
        } else {
          this.$message({
            message: '登出失败，请刷新页面！',
            type: 'warning',
            center: 'true'
          });
        }
      }).catch((error) => {
        console.log(error);
        this.$message({
          message: '服务器错误',
          type: 'error',
          center: 'true'
        });
      });
    }
  },
  mounted() {
    var url = "https://api2.ikin.top/user/admin";
    this.$axios({
      method: 'get',
      url: url,
      params: {
        userId: sessionStorage.getItem("userId"),
        token: sessionStorage.getItem("token")
      }
    }).then((response) => {
      if (response.data.data.isSuccess) {
        sessionStorage.setItem("isAdmin", 1);
        this.isAdmin = 1
      }else if(response.data.code === 301 || response.data.code === 302){
        this.$message({
          message: '用户信息失效',
          type: 'warning',
          center: 'true'
        });
        sessionStorage.setItem("isLogin", 0);
        this.$router.push("/login");
      } else {
        sessionStorage.setItem("isAdmin", 0);
      }
    }).catch((error) => {
      console.log(error);
      this.$message({
        message: '服务器错误',
        type: 'error',
        center: 'true'
      });
      sessionStorage.setItem("isAdmin", 0);
    });
  }
}
</script>

<style  lang="scss">

@import "../../assets/style/style";

#user-center {
  display:flex;
  align-items:center;
  justify-content:center;
}

.user-center-box {
  width: 80vw;
  height: 87vh;
  color: #61677C;
  box-shadow: -5px -5px 20px $color-white,  5px 5px 20px $color-shadow;
  font-weight: 600;
  border-radius: $ruler*2;
  margin: 0 auto;
  position: relative;
  top: 7vh;
}

.function-box {
  background-color: #FFFFFF;
  width: 70vw;
  height: 14vh;
  margin-top: 35px;
  margin-left: 5vw;
  border-radius: $ruler;
  box-shadow:  inset 5px 5px 10px $color-shadow, inset -15px -15px 10px $color-white;
}

.user-box {
  background-color: #FFFFFF;
  width: 75vw;
  height: 63vh;
  top: 29vh;
  position: absolute;
  border-radius: $ruler;
  box-shadow:  inset 5px 5px 10px $color-shadow, inset -15px -15px 10px $color-white;
}

.user-center-btn {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 12px;
  top: 15px;
}

.el-icon-s-marketing {
  font-size: 15px;
  position: absolute;
  top: 30%;
  right: 5%;
}

.user-function {
  width: 100px;
  height: 80px;
  margin-top: 20px;
  margin-left: 20px;
  transition: all 0.2s ease-in-out;


  &:hover {
    color: #4e93e8;
  }

  &:active {
    color: #0e54b8;
  }
}

.user-function-logout {
  width: 100px;
  height: 80px;
  margin-top: 20px;
  margin-left: 20px;
  transition: all 0.2s ease-in-out;


  &:hover {
    color: #e84e4e;
  }

  &:active {
    color: #b80e0e;
  }
}

.user-function-logo {
  font-size: 35px;
}

.user-function-text {
  font-size: 13px;
}

</style>
