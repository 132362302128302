import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css' //新添加，避免後期打包樣式不同，要放在import App from './App';之前
import App from './App.vue'
import ElementUI from 'element-ui' //新添加
import axios from 'axios'
import md5 from 'js-md5';
import router from './router';
import moment from 'moment';
//全量引入
//import * as echarts from 'echarts';

// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import {
  LineChart
} from 'echarts/charts';
// 引入提示框，标题，直角坐标系组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent
} from 'echarts/components';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import {
  CanvasRenderer
} from 'echarts/renderers';


Vue.prototype.$moment = moment;
Vue.prototype.$md5 = md5;    //全局注册，md5加密
Vue.prototype.$axios = axios;    //全局註冊，使用方法為:this.$axios
Vue.use(ElementUI);   //新添加
// 注册必须的组件
echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, LineChart, CanvasRenderer]
);
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;
//axios.defaults.baseURL = "https://ship.ikin.top"

new Vue({
  render: h => h(App),
  router
}).$mount('#app');
