<template>
  <div id="report">
    <div id="report-container">
    <el-row>
      <el-col :span="12">
        <p class="report-subtitle">以下是您所设计的船舶</p>
        <p class="report-title">{{ this.result.shipName }}</p>

        <p class="report-column-title">基本信息</p>
        <div class="report-text">
          <el-row>
            <el-col :span="8">
              <div class="report-text-label">类型：</div>
            </el-col>
          <el-col :span="12">
            <div class="report-text-info">{{ this.result.shipType }}</div>
          </el-col>
          </el-row>
        </div>
        <div class="report-text">
          <el-row>
            <el-col :span="8">
              <div class="report-text-label">国家：</div>
            </el-col>
            <el-col :span="12">
              <div class="report-text-info">{{ this.result.shipCountry }}</div>
            </el-col>
          </el-row>
        </div>

        <p class="report-column-title">主尺度信息</p>
        <div class="report-text">
          <el-row>
            <el-col :span="8">
              <div class="report-text-label">吨位：</div>
            </el-col>
            <el-col :span="12">
              <div class="report-text-info">{{ this.result.tonnage }} t</div>
            </el-col>
          </el-row>
        </div>
        <div class="report-text">
          <el-row>
            <el-col :span="8">
              <div class="report-text-label">船体自重：</div>
            </el-col>
            <el-col :span="12">
              <div class="report-text-info">{{ this.result.hullWeight.toFixed(2) }} t</div>
            </el-col>
          </el-row>
        </div>
        <div class="report-text">
          <el-row>
            <el-col :span="8">
              <div class="report-text-label">排水量：</div>
            </el-col>
            <el-col :span="12">
              <div class="report-text-info">{{ this.result.displacement.toFixed(2) }} t</div>
            </el-col>
          </el-row>
        </div>
        <div class="report-text">
          <el-row>
            <el-col :span="8">
              <div class="report-text-label">船长：</div>
            </el-col>
            <el-col :span="12">
              <div class="report-text-info">{{ this.result.length.toFixed(2) }} m</div>
            </el-col>
          </el-row>
        </div>
        <div class="report-text">
          <el-row>
            <el-col :span="8">
              <div class="report-text-label">船宽：</div>
            </el-col>
            <el-col :span="12">
              <div class="report-text-info">{{ this.result.width.toFixed(2) }} m</div>
            </el-col>
          </el-row>
        </div>
        <div class="report-text">
          <el-row>
            <el-col :span="8">
              <div class="report-text-label">船深：</div>
            </el-col>
            <el-col :span="12">
              <div class="report-text-info">{{ this.result.depth.toFixed(2) }} m</div>
            </el-col>
          </el-row>
        </div>
        <div class="report-text">
          <el-row>
            <el-col :span="8">
              <div class="report-text-label">垂线间长：</div>
            </el-col>
            <el-col :span="12">
              <div class="report-text-info">{{ this.result.lengthBetweenPerpendiculars.toFixed(2) }} m</div>
            </el-col>
          </el-row>
        </div>
        <div class="report-text">
          <el-row>
            <el-col :span="8">
              <div class="report-text-label">吃水：</div>
            </el-col>
            <el-col :span="12">
              <div class="report-text-info">{{ this.result.draft.toFixed(2) }} m</div>
            </el-col>
          </el-row>
        </div>

        <p class="report-column-title">船形系数</p>
        <div class="report-text">
          <el-row>
            <el-col :span="8">
              <div class="report-text-label">方形系数：</div>
            </el-col>
            <el-col :span="12">
              <div class="report-text-info">{{ this.result.blockCoefficient.toFixed(5) }}</div>
            </el-col>
          </el-row>
        </div>
        <div class="report-text">
          <el-row>
            <el-col :span="8">
              <div class="report-text-label">棱形系数：</div>
            </el-col>
            <el-col :span="12">
              <div class="report-text-info">{{ this.result.prismaticCoefficient.toFixed(5) }}</div>
            </el-col>
          </el-row>
        </div>
        <div class="report-text">
          <el-row>
            <el-col :span="8">
              <div class="report-text-label">水线面系数：</div>
            </el-col>
            <el-col :span="12">
              <div class="report-text-info">{{ this.result.waterlineCoefficient.toFixed(5) }}</div>
            </el-col>
          </el-row>
        </div>
        <div class="report-text">
          <el-row>
            <el-col :span="8">
              <div class="report-text-label">中横剖面系数：</div>
            </el-col>
            <el-col :span="12">
              <div class="report-text-info">{{ this.result.midshipSectionCoefficient.toFixed(5) }}</div>
            </el-col>
          </el-row>
        </div>

        <p class="report-column-title">其他信息</p>
        <div class="report-text">
          <el-row>
            <el-col :span="8">
              <div class="report-text-label">自持力：</div>
            </el-col>
            <el-col :span="12">
              <div class="report-text-info">{{ this.result.endurance }} nmile</div>
            </el-col>
          </el-row>
        </div>
        <div class="report-text">
          <el-row>
            <el-col :span="8">
              <div class="report-text-label">油耗：</div>
            </el-col>
            <el-col :span="12">
              <div class="report-text-info">{{ this.result.fuelConsumption.toFixed(2) }} t/d</div>
            </el-col>
          </el-row>
        </div>
        <div class="report-text">
          <el-row>
            <el-col :span="8">
              <div class="report-text-label">主机功率：</div>
            </el-col>
            <el-col :span="12">
              <div class="report-text-info">{{ this.result.monitorPowerSMCR.toFixed(2) }} kW</div>
            </el-col>
          </el-row>
        </div>
        <div class="report-text">
          <el-row>
            <el-col :span="8">
              <div class="report-text-label">横稳性半径：</div>
            </el-col>
            <el-col :span="12">
              <div class="report-text-info">{{ this.result.metacentricRadius.toFixed(2) }} m</div>
            </el-col>
          </el-row>
        </div>
        <div class="report-text">
          <el-row>
            <el-col :span="8">
              <div class="report-text-label">重心高度：</div>
            </el-col>
            <el-col :span="12">
              <div class="report-text-info">{{ this.result.metacentricHeight.toFixed(2) }} m</div>
            </el-col>
          </el-row>
        </div>
        <div class="report-text">
          <el-row>
            <el-col :span="8">
              <div class="report-text-label">浮心高度：</div>
            </el-col>
            <el-col :span="12">
              <div class="report-text-info">{{ this.result.floatingHeight.toFixed(2) }} m</div>
            </el-col>
          </el-row>
        </div>
        <div class="report-text">
          <el-row>
            <el-col :span="8">
              <div class="report-text-label">设计航速：</div>
            </el-col>
            <el-col :span="12">
              <div class="report-text-info">{{ this.result.designSpeed.toFixed(2) }} kn</div>
            </el-col>
          </el-row>
        </div>
        <div class="report-text">
          <el-row>
            <el-col :span="8">
              <div class="report-text-label">载员：</div>
            </el-col>
            <el-col :span="12">
              <div class="report-text-info">{{ this.result.crew }} 人</div>
            </el-col>
          </el-row>
        </div><div class="report-text">
        <el-row>
          <el-col :span="8">
            <div class="report-text-label">载客：</div>
          </el-col>
          <el-col :span="12">
            <div class="report-text-info">{{ this.result.passenger }} 人</div>
          </el-col>
        </el-row>
      </div>
      </el-col>
      <el-col :span="12">
        <div class="report-pic-container">
          <img class="report-picture" src="./Dry-Bulk.jpeg" />
          <div class="report-drawing">
            <ManoeuvreResult :axis-data="axisData" :angle="angle" ref="result"></ManoeuvreResult>
          </div>
        </div>
      </el-col>
    </el-row>
    </div>


  </div>
</template>

<script>
import ManoeuvreResult from "@/components/ManoeuvreResult/ManoeuvreResult";

export default {
  name: "ReportSummery",
  props: ['result', 'axisData', 'angle'],
  components: {
    ManoeuvreResult
  },
  methods: {

  },
  mounted() {
    this.$refs.result.drawChart();
  }
}
</script>

<style scoped>
#report-container {
  max-height: 60vh;
  overflow:scroll;
}

.report-title {
  font-size: 40px;
  font-weight: 600;
  font-family: SF Pro Display,SF Pro Icons,AOS Icons,Helvetica Neue,Helvetica,Arial,sans-serif;
  text-align: left;
  margin-left: 10vw;
  margin-top: 0;
  margin-bottom: 0;
}

.report-subtitle {
  font-size: 13px;
  line-height: 1.42861;
  font-weight: 400;
  color: #EF5602;
  margin-bottom: 0;
  margin-top: 25px;
  margin-left: 10vw;
  text-align: left;
}

.report-column-title {
  font-size: 12px;
  font-weight: 400;
  color: #888;
  margin-top: 30px;
  margin-left: 10vw;
  margin-bottom: 5px;
  text-align: left;
}

.report-text {
  font-size: 17px;
  line-height: 1.23543;
  font-weight: 400;
  color: #555;
  text-align: left;
  margin-left: 10vw;
}

.report-text-label {

  display: inline;
}

.report-text-info {
  font-size: 17px;
  line-height: 1.23543;
  font-weight: 600;
  color: #000;
  text-align: right;
  display: inline;
}

.report-picture {
  height: 30vh;
  margin-top: 5vh;
  border-radius: 10px;
  box-shadow: -2px -2px 5px #EBECF0, 2px 2px 5px #5f5f5f;
}
.report-drawing {
  margin-top: 130px;
  margin-left: 2vw;
}
</style>
