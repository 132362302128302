<template>
  <div id="el-welcome-container">
    <h1 class="el-welcome-h1">
      <span class="el-welcome-span">
        <i class="el-welcome-i" id="welcome-text" @click="doRoute">欢迎使用</i>
      </span>

      <span class="el-welcome-span" >
        <i class="el-welcome-i">{{name}}</i>
      </span>
    </h1>
  </div>
</template>

<script>
export default {
  name: "Welcome",
  data() {
    return {
      name: sessionStorage.getItem("userName")
    }
  },
  methods: {
    doRoute: function () {
      this.$router.push("/center");
    }
  },
  mounted() {
    var o = document.getElementsByClassName("el-welcome-span")[0];
    o.addEventListener("webkitAnimationEnd", function() {
      setTimeout(() => {
        var text = document.getElementById("welcome-text");
        text.click();
      }, 2000)
    })
  }
}
</script>

<style>
#el-welcome-container {
  font-size: 32px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  margin-top: 30vh;
}

.el-welcome-h1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.el-welcome-h1 {
  margin: 0;
  letter-spacing: -2px;
}

.el-welcome-span {
  display: inline-block;
  overflow: hidden;
  position: relative;
  top: 5rem;
  animation: 1.2s swift-up ease-in-out forwards;
}

.el-welcome-i {
  font-style: normal;
  position: relative;
  top: 3rem;
  animation: 1.2s swift-up ease-in-out forwards;
  opacity: 0;
  color: #283038;
}

@keyframes swift-up {
  to {
    top: 0rem;
    opacity: 1;
  }
}

span:nth-of-type(1) i {
  animation-delay: 0s;
}

span:nth-of-type(2) i {
  animation-delay: .3s;
}


span:nth-of-type(1) {
  animation-delay: 0s;
}

span:nth-of-type(2) {
  animation-delay: .3s;
}

</style>
