<template>
  <div id="parent-choice">

    <el-select v-model="choice" placeholder="请选择母型船" @change="changeChoice">
      <el-option v-for="item in ships" :key="item.id" :label="item.shipName" :value="item.id">
        <span style="float: left">{{ item.shipName }}</span>
        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.shipType }}</span>
      </el-option>
    </el-select>

    <el-row class="parent-ship-info-box">
      <el-col :span="12">
        <div>
          <el-form :model="parentShip" class="parent-ship-form" label-width="120px" size="mini">

            <el-form-item label="船名">
              <el-input class="parent-ship-input" v-model="parentShip.shipName" placeholder="船名"  ></el-input>
            </el-form-item>
            <el-form-item label="国家">
              <el-input class="parent-ship-input" v-model="parentShip.shipCountry" placeholder="国家"  ></el-input>
            </el-form-item>
            <el-form-item label="自重">
              <el-input class="parent-ship-input" v-model="parentShip.hullWeight" placeholder="自重"  ></el-input>
            </el-form-item>
            <el-form-item label="船宽">
              <el-input class="parent-ship-input" v-model="parentShip.width" placeholder="船宽"  ></el-input>
            </el-form-item>
            <el-form-item label="垂线间长">
              <el-input class="parent-ship-input" v-model="parentShip.lengthBetweenPerpendiculars" placeholder="垂线间长"  ></el-input>
            </el-form-item>
            <el-form-item label="自持力">
              <el-input class="parent-ship-input" v-model="parentShip.endurance" placeholder="自持力"  ></el-input>
            </el-form-item>
            <el-form-item label="航速（SMCR）">
              <el-input class="parent-ship-input" v-model="parentShip.sailingSpeedSMCR" placeholder="航速（SMCR）"  ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </el-col>

      <el-col :span="12">
        <div>
          <el-form :model="parentShip" class="parent-ship-form" label-width="120px" size="mini">

            <el-form-item label="类型">
              <el-input class="parent-ship-input" v-model="parentShip.shipType" placeholder="类型"  ></el-input>
            </el-form-item>
            <el-form-item label="吨位">
              <el-input class="parent-ship-input" v-model="parentShip.tonnage" placeholder="吨位"  ></el-input>
            </el-form-item>
            <el-form-item label="船长">
              <el-input class="parent-ship-input" v-model="parentShip.length" placeholder="船长"  ></el-input>
            </el-form-item>
            <el-form-item label="船深">
              <el-input class="parent-ship-input" v-model="parentShip.depth" placeholder="船深"  ></el-input>
            </el-form-item>
            <el-form-item label="吃水">
              <el-input class="parent-ship-input" v-model="parentShip.draft" placeholder="吃水"  ></el-input>
            </el-form-item>
            <el-form-item label="油耗">
              <el-input class="parent-ship-input" v-model="parentShip.fuelConsumption" placeholder="油耗"  ></el-input>
            </el-form-item>
            <el-form-item label="载员">
              <el-input class="parent-ship-input" v-model="parentShip.crew" placeholder="载员"  ></el-input>
            </el-form-item>
            <el-button type="primary" round @click="chooseParent">下一步</el-button>
          </el-form>
        </div>
      </el-col>

    </el-row>

    </div>
</template>

<script>
export default {
  name: "ParentChoose",
  data() {
    return {
      ships: [],
      ship: {
        id: "",
        shipName: "",
        shipType: "",
        shipCountry: "",
        tonnage: ""
      },
      parentShip: {
        id: "",
        shipName: "",
        shipType: "",
        shipCountry: "",
        tonnage: "",
        hullWeight: "",
        length: "",
        width: "",
        depth: "",
        lengthBetweenPerpendiculars: "",
        draft: "",
        endurance: "",
        fuelConsumption: "",
        monitorPowerSMCR: "",
        mainEngineSpeedSMCR: "",
        sailingSpeedSMCR: "",
        monitorPowerCSO: "",
        mainEngineSpeedCSO: "",
        sailingSpeedCSO: "",
        crew: "",
        passenger: "",
        metacentricRadius: "",
        metacentricHeight: "",
        floatingHeight: "",
        metacentricPeriod: "",
        createId: "",
        createTime: "",
        updateId: "",
        updateTime: ""
      },
      choice: ""
    }
  },
  methods: {
    changeChoice: function (){
      var url = "https://api2.ikin.top/ship/design/parent/detail/" + this.choice;
      this.$axios({
        method: 'get',
        url: url,
        params: {
          userId: sessionStorage.getItem("userId"),
          token: sessionStorage.getItem("token")
        }
      }).then((response) => {
        if (response.data.success) {
          this.parentShip = response.data.data;
        }else if(response.data.code === 301 || response.data.code === 302){
          this.$message({
            message: '用户信息失效',
            type: 'warning',
            center: 'true'
          });
          sessionStorage.setItem("isLogin", 0);
          this.$router.push("/login");
        } else {
          this.$message({
            message: '母型船列表拉取失败',
            type: 'warning',
            center: 'true'
          })
        }
      }).catch((error) => {
        console.log(error);
        this.$message({
          message: '服务器错误',
          type: 'error',
          center: 'true'
        });
      });
    },

    chooseParent: function (){
      var url = "https://api2.ikin.top/ship/design/parent/choose/" + this.choice;
      if (this.choice === "") {
        this.$message({
          message: '选择失败',
          type: 'warning',
          center: 'true'
        });
      }else {
        this.$axios({
          method: 'get',
          url: url,
          params: {
            userId: sessionStorage.getItem("userId"),
            token: sessionStorage.getItem("token")
          },
        }).then((response) => {
          if (response.data.data.isSuccess) {
            this.$message({
              message: '选择成功',
              type: 'success',
              center: 'true'
            });
            this.$emit('nextStep');
          } else if(response.data.code === 301 || response.data.code === 302) {
            this.$message({
              message: '用户信息失效',
              type: 'warning',
              center: 'true'
            });
            sessionStorage.setItem("isLogin", 0);
            this.$router.push("/login");
          } else {
            this.$message({
              message: '选择失败',
              type: 'warning',
              center: 'true'
            });
          }
        }).catch((error) => {
          console.log(error);
          this.$message({
            message: '服务器错误',
            type: 'error',
            center: 'true'
          });
        });
      }
    }
  },
  mounted() {
    var url = "https://api2.ikin.top/ship/design/parent/list";
    this.$axios({
      method: 'get',
      url: url,
      params: {
        userId: sessionStorage.getItem("userId"),
        token: sessionStorage.getItem("token")
      }
    }).then((response) => {
      if (response.data.success) {
        this.ships = response.data.data;

      }else if(response.data.code === 301 || response.data.code === 302){
        this.$message({
          message: '用户信息失效',
          type: 'warning',
          center: 'true'
        });
        sessionStorage.setItem("isLogin", 0);
        this.$router.push("/login");
      } else {
        this.$message({
          message: '母型船列表拉取失败',
          type: 'warning',
          center: 'true'

        })
      }
    }).catch((error) => {
      console.log(error);
      this.$message({
        message: '服务器错误',
        type: 'error',
        center: 'true'
      });
    });
  }
}
</script>

<style scoped>
#parent-choice {
  margin-top: 15px;
}

.parent-ship-info-box {
  margin-top: 15px;
  width: 70vw;
}

.el-form-item--mini  {
  margin: 0px;
}

</style>
