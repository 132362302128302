<template>
  <div id="design-center">
    <div class="center-box">

      <button class="design-center-btn" type="button" @click="$emit('CenterSwitch')">
        <i class="icon el-icon-user-solid"></i>
      </button>

      <div class="step-box">
        <el-steps class="el-steps" :active="step" finish-status="success" align-center>
          <el-step title="母型船选择" icon="el-icon-s-data" description="选择一艘船作为母型船"></el-step>
          <el-step title="新船录入" icon="el-icon-document" description="输入要求新船的信息"></el-step>
          <el-step title="主尺度设计" icon="el-icon-edit" description="查看新船初步设计方案"></el-step>
          <el-step title="操纵性模拟" icon="el-icon-ship" description="查看新船操纵性模拟数据"></el-step>
          <el-step title="报告获取" icon="el-icon-upload" description="获取新船设计报告"></el-step>
        </el-steps>
      </div>
    </div>

    <div class="design-box">
      <ParentChoose v-if="step === 0" @nextStep="nextStep" />
      <NewShipInfo v-else-if="step === 1" @newStepWithData="newStepWithData" />
      <NewShipResult v-else-if="step === 2" :result="newShipResult" @nextStep="nextStep" />
      <ManoeuvreSimulate v-else-if="step === 3" @newStepWithAxis="newStepWithAxis" @anglePass="anglePass" />
      <ReportSummery v-else-if="step === 4" :result="newShipResult" :axis-data="axisData" :angle="angle"/>
    </div>
  </div>
</template>

<script>

import ParentChoose from "@/components/ParentChoose/ParentChoose";
import NewShipInfo from "@/components/NewShipInfo/NewShipInfo";
import NewShipResult from "@/components/NewShipResult/NewShipResult";
import ManoeuvreSimulate from "@/components/ManoeuvreSimulate/ManoeuvreSimulate";
import ReportSummery from "@/components/ReportSummery/ReportSummery";

export default {
  name: "Center",
  components: {
    ParentChoose,
    NewShipInfo,
    NewShipResult,
    ManoeuvreSimulate,
    ReportSummery
  },
  data() {
    return {
      step: 0,
      newShipResult: {},
      axisData: [],
      angle: 0
    }
  },
  methods: {
    nextStep: function () {
      this.step += 1;
    },

    newStepWithData: function(param) {
      this.newShipResult = param;
      this.step += 1;
    },

    newStepWithAxis: function (param) {
      this.axisData = param;
      this.step += 1;
    },

    anglePass: function (param) {
      console.log(this.angle);
      this.angle = param;
      console.log(this.angle);
    }
  }
}
</script>

<style lang="scss">

@import "../../assets/style/style";

#design-center {
  display:flex;
  align-items:center;
  justify-content:center;
}

.center-box {
  width: 80vw;
  height: 87vh;
  color: #61677C;
  box-shadow: -5px -5px 20px $color-white,  5px 5px 20px $color-shadow;
  font-weight: 600;
  border-radius: $ruler*2;
  margin: 0 auto;
  position: relative;
  top: 7vh;
}

.step-box {
  background-color: #FFFFFF;
  width: 70vw;
  height: 14vh;
  margin-top: 20px;
  padding-top: 15px;
  margin-left: 5vw;
  border-radius: $ruler;
  box-shadow:  inset 5px 5px 10px $color-shadow, inset -15px -15px 10px $color-white;
}

.el-steps {
  padding-top: 10px;
}

.design-box {
  background-color: #FFFFFF;
  width: 75vw;
  height: 63vh;
  top: 29vh;
  position: absolute;
  border-radius: $ruler;
  box-shadow:  inset 5px 5px 10px $color-shadow, inset -15px -15px 10px $color-white;
}

.design-center-btn {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 12px;
  top: 15px;
}

.el-icon-user-solid {
  font-size: 15px;
  position: absolute;
  top: 30%;
  right: 5%;
}


</style>
