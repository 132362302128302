<template>
  <div id="ship-manage">
    <el-row class="parent-ship-info-box">
      <el-col :span="7">
        <div class="parent-ship-info" >
          <el-form :model="newParentShip" class="parent-ship-form" label-width="50px" size="mini">
            <el-form-item label="船名"  style="width: 210px">
              <el-input class="parent-ship-input" v-model="newParentShip.shipName" placeholder="船名" style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="种类" style="width: 210px">
              <el-select v-model="newParentShip.shipType" placeholder="请选择新船类型" style="width: 150px">
                <el-option label="散货船" value="BULK"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="国家"  style="width: 210px">
              <el-select v-model="newParentShip.shipCountry" placeholder="请选择国家" style="width: 150px">
                <el-option label="中国 🇨🇳" value="CHINA"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="吨位"  style="width: 210px">
              <el-input class="parent-ship-input" v-model="newParentShip.tonnage" placeholder="吨位" style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="自重"  style="width: 210px">
              <el-input class="parent-ship-input" v-model="newParentShip.hullWeight" placeholder="自重" style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="船长"  style="width: 210px">
              <el-input class="parent-ship-input" v-model="newParentShip.length" placeholder="船长" style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="船宽"  style="width: 210px">
              <el-input class="parent-ship-input" v-model="newParentShip.width" placeholder="船宽" style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="船深"  style="width: 210px">
              <el-input class="parent-ship-input" v-model="newParentShip.depth" placeholder="船深" style="width: 150px"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="parent-ship-info" >
          <el-form :model="newParentShip" class="parent-ship-form" label-width="70px" size="mini">
            <el-form-item label="垂线间长" style="width: 210px">
              <el-input class="parent-ship-input" v-model="newParentShip.lengthBetweenPerpendiculars" placeholder="垂线间长" style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="吃水" style="width: 210px">
              <el-input class="parent-ship-input" v-model="newParentShip.draft" placeholder="吃水" style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="续航力" style="width: 210px">
              <el-input class="parent-ship-input" v-model="newParentShip.endurance" placeholder="续航力" style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="油耗" style="width: 210px">
              <el-input class="parent-ship-input" v-model="newParentShip.fuelConsumption" placeholder="油耗" style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="载员" style="width: 210px">
              <el-input class="parent-ship-input" v-model="newParentShip.crew" placeholder="载员" style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="载客" style="width: 210px">
              <el-input class="parent-ship-input" v-model="newParentShip.passenger" placeholder="载客" style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="浮心高度" style="width: 210px">
              <el-input class="parent-ship-input" v-model="newParentShip.floatingHeight" placeholder="浮心高度" style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="横稳周期" style="width: 210px">
              <el-input class="parent-ship-input" v-model="newParentShip.metacentricPeriod" placeholder="横稳周期" style="width: 150px"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="parent-ship-info" >
          <el-form :model="newParentShip" class="parent-ship-form" label-width="135px" size="mini">
            <el-form-item label="主机功率（SMCR）" style="width: 200px">
              <el-input class="parent-ship-input" v-model="newParentShip.monitorPowerSMCR" placeholder="主机功率（SMCR）" style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="主机转速（SMCR）" style="width: 200px">
              <el-input class="parent-ship-input" v-model="newParentShip.mainEngineSpeedSMCR" placeholder="主机转速（SMCR）" style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="航速（SMCR）" style="width: 200px">
              <el-input class="parent-ship-input" v-model="newParentShip.sailingSpeedSMCR" placeholder="航速（SMCR）" style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="主机功率（CSO）" style="width: 200px">
              <el-input class="parent-ship-input" v-model="newParentShip.monitorPowerCSO" placeholder="主机功率（CSO）" style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="主机转速（CSO）" style="width: 200px">
              <el-input class="parent-ship-input" v-model="newParentShip.mainEngineSpeedCSO" placeholder="主机转速（CSO）" style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="航速（CSO）" style="width: 200px">
              <el-input class="parent-ship-input" v-model="newParentShip.sailingSpeedCSO" placeholder="航速（CSO）" style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="横稳性半径" style="width: 200px">
              <el-input class="parent-ship-input" v-model="newParentShip.metacentricRadius" placeholder="横稳性半径" style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="重心高度" style="width: 200px">
              <el-input class="parent-ship-input" v-model="newParentShip.metacentricHeight" placeholder="重心高度" style="width: 150px"></el-input>
            </el-form-item>
            <el-button type="primary" round @click="addParentShip">添加新船数据</el-button>

          </el-form>
        </div>
      </el-col>

    </el-row>
  </div>
</template>

<script>
export default {
  name: "ShipManage",
  data() {
    return {
      newParentShip: {
        shipName: "",
        shipType: "",
        shipCountry: "",
        tonnage: "",
        hullWeight: "",
        length: "",
        width: "",
        depth: "",
        lengthBetweenPerpendiculars: "",
        draft: "",
        endurance: "",
        fuelConsumption: "",
        monitorPowerSMCR: "",
        mainEngineSpeedSMCR: "",
        sailingSpeedSMCR: "",
        monitorPowerCSO: "",
        mainEngineSpeedCSO: "",
        sailingSpeedCSO: "",
        crew: "",
        passenger: "",
        metacentricRadius: "",
        metacentricHeight: "",
        floatingHeight: "",
        metacentricPeriod: ""
      }
    }
  },
  methods: {
    addParentShip: function () {
      var url = "https://api2.ikin.top/ship/design/parent/add";
      if(this.newParentShip.shipName === "" ||
          this.newParentShip.shipType === "" ||
          this.newParentShip.shipCountry === "" ||
          this.newParentShip.tonnage === "" ||
          this.newParentShip.hullWeight === "" ||
          this.newParentShip.length === "" ||
          this.newParentShip.width === "" ||
          this.newParentShip.depth === "" ||
          this.newParentShip.lengthBetweenPerpendiculars === "" ||
          this.newParentShip.draft === "" ||
          this.newParentShip.endurance === "" ||
          this.newParentShip.fuelConsumption === "" ||
          this.newParentShip.monitorPowerSMCR === "" ||
          this.newParentShip.mainEngineSpeedSMCR === "" ||
          this.newParentShip.sailingSpeedSMCR === "" ||
          this.newParentShip.monitorPowerCSO === "" ||
          this.newParentShip.mainEngineSpeedCSO === "" ||
          this.newParentShip.sailingSpeedCSO === "" ||
          this.newParentShip.crew === "" ||
          this.newParentShip.passenger === "" ||
          this.newParentShip.metacentricRadius === "" ||
          this.newParentShip.metacentricHeight === "" ||
          this.newParentShip.floatingHeight === "" ||
          this.newParentShip.metacentricPeriod === "") {
        this.$message({
          message: '请确认参数输入完整',
          type: 'warning',
          center: 'true'
        });
      }else {
        this.$axios({
          method: 'post',
          url: url,
          params: {
            userId: sessionStorage.getItem("userId"),
            token: sessionStorage.getItem("token")
          },
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: JSON.stringify({
            shipName: this.newParentShip.shipName,
            shipType: this.newParentShip.shipType,
            shipCountry: this.newParentShip.shipCountry,
            tonnage: this.newParentShip.tonnage,
            hullWeight: this.newParentShip.hullWeight,
            length: this.newParentShip.length,
            width: this.newParentShip.width,
            depth: this.newParentShip.depth,
            lengthBetweenPerpendiculars: this.newParentShip.lengthBetweenPerpendiculars,
            draft: this.newParentShip.draft,
            endurance: this.newParentShip.endurance,
            fuelConsumption: this.newParentShip.fuelConsumption,
            monitorPowerSMCR: this.newParentShip.monitorPowerSMCR,
            mainEngineSpeedSMCR: this.newParentShip.mainEngineSpeedSMCR,
            sailingSpeedSMCR: this.newParentShip.sailingSpeedSMCR,
            monitorPowerCSO: this.newParentShip.monitorPowerCSO,
            mainEngineSpeedCSO: this.newParentShip.mainEngineSpeedCSO,
            sailingSpeedCSO: this.newParentShip.sailingSpeedCSO,
            crew: this.newParentShip.crew,
            passenger: this.newParentShip.passenger,
            metacentricRadius: this.newParentShip.metacentricRadius,
            metacentricHeight: this.newParentShip.metacentricHeight,
            floatingHeight: this.newParentShip.floatingHeight,
            metacentricPeriod: this.newParentShip.metacentricPeriod
          })
        }).then((response) => {
          if (response.data.data.isSuccess) {
            this.$message({
              message: '提交成功',
              type: 'success',
              center: 'true'
            });
          }else if(response.data.code === 301 || response.data.code === 302){
            this.$message({
              message: '用户信息失效',
              type: 'warning',
              center: 'true'
            });
            sessionStorage.setItem("isLogin", 0);
            this.$router.push("/login");
          }  else {
            this.$message({
              message: '提交失败',
              type: 'warning',
              center: 'true'
            })
          }
        }).catch((error) => {
          this.$message({
            message: '服务器错误',
            type: 'error',
            center: 'true'
          });
          console.error(error);
        });
      }
    }
  }
}
</script>

<style scoped>
.parent-ship-info-box{
  margin-top: 10px;
}

.el-form-item {
  margin-bottom: 0;
}

.parent-ship-input {
  padding-left: 0px;
}
</style>
