<template>
  <div id="result">
    <div id="chart"></div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: "ManoeuvreResult",
  props: {
    axisData: [],
    angle: Number
  },
  data() {
    return {
      myChart: Object
    }
  },
  computed: {
    chartAngle: function () {
      return this.angle
    }
  },
  methods: {
    setChart: function () {
      var mainContainer = document.getElementById('chart');

      //用于使chart自适应高度和宽度,通过窗体高宽计算容器高宽
      var resizeMainContainer = function () {
        mainContainer.style.width = window.innerWidth * 0.35 + 'px';
        mainContainer.style.height = window.innerHeight * 0.45 + 'px';
      };
      //设置div容器高宽
      resizeMainContainer();
      // 初始化图表
      this.myChart = this.$echarts.init(mainContainer);
      $(window).on('resize',function(){
        //屏幕大小自适应，重置容器高宽
        resizeMainContainer();
        this.myChart.resize();
      });
    },
    drawChart: function () {
      this.myChart.setOption({
        title: {
          left: 'center',
          text: '新船回转轨迹图',
          subtext: '角度：' + this.angle,
        },
        tooltip: {
          trigger: 'axis',
          formatter: "坐标值 : <br/>[{b}  {c}]"
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}'
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}'
          }
        },
        series: [
          {
            name: '新船回转轨迹图',
            type: 'line',
            smooth: true,
            barCategoryGap: 25,
            data: this.axisData
          }
        ]
      });
    }
  },
  mounted() {
    this.setChart();
  }
}
</script>

<style scoped>
#chart {
  margin-top: -6vh;
}
</style>
