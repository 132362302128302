<template>
  <div id="app">
    <router-view />
    <div class="system-footer">
      <p class="system-copyright">Designed by <a href="https://www.ikin.top">iKin Huang</a><br>2019-2021 ©️ ikin.top 版权所有</p>
    </div>
  </div>
</template>

<script>
export default {

  name: 'App',
  components: {
  }
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  width: 100vw;
  height: 100vh;
}

#menu-switch {
  float:right;
  margin-top: 10px;
  margin-right: 15px;
}

.el-message{
  border-radius: 30px;
  box-shadow: -5px -5px 20px #FFF,  15px 15px 20px #BABECC;
  background-color: #FFF;
  border: #FFF;
}

.system-footer {
  position: fixed;
  bottom: 0;
  text-align: center;
  width: 100%;
  z-index: 100;
}

.system-copyright {
  color: #888888;
  font-size: 12px;
}


</style>
