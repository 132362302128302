<template>
  <div class="the-end-of-my-college">
    <div class="close_btn" style="text-shadow: 0 0 0 #FFF;" @click="closeTheEnd">X</div>
    <img class="start-pic" src="./play.png" v-show="!start" @click="startPlay"/>

    <marquee id="marquee" direction="up" width="1000" height="1000" v-show="start" loop="1" scrollamount="2">
      <div class="text" style="text-shadow: 0 0 0 #FFF;color: #EBECF0">
        <div class="marquee_title">尾  声</div>
        <div class="marquee_text">好啦，到这里，我的学生生涯也就要结束啦</div>
        <div class="marquee_text">四年时间经历了很多事情</div>
        <div class="marquee_text">结识了一群很有意思的朋友</div>
        <img class="marquee_pic" width="500" src="https://ikinhuang.oss-cn-shanghai.aliyuncs.com/1.jpeg?versionId=CAEQDRiBgMCF1eLWyxciIDhlNGEwZTkwODU5NTRjYjNiMWU0Y2E2OTViZmZhZjg4" />
        <div class="marquee_text">擁有過一段只剩低回憶的感情</div>
        <img class="marquee_pic" width="400"  src="https://ikinhuang.oss-cn-shanghai.aliyuncs.com/2.jpeg?versionId=CAEQDRiBgIDX4uXWyxciIDY1MGRkNTQ2YTExZDRlMGU4YmFhOTg1OWRmOTIyNzJh" />
        <div class="marquee_text">四年的时间，我学会了很多，也成长了很多</div>
        <img class="marquee_pic" width="400"  src="https://ikinhuang.oss-cn-shanghai.aliyuncs.com/3.jpeg?versionId=CAEQDRiBgIDv1eLWyxciIGFiYWM3NGRhMzYxNTQ1NzU4ZjE4ZTkyZmJlZjMyNWIy" />
        <div class="marquee_text">还记得刚大一的时候，我总是厌烦学校的生活，放假就往家里跑</div>
        <div class="marquee_text">现在，真的要离开了，我却舍不得了</div>
        <img class="marquee_pic" width="500"  src="https://ikinhuang.oss-cn-shanghai.aliyuncs.com/4.jpeg?versionId=CAEQDRiBgIC51eLWyxciIDdkY2Y4NmU2NGQ4MzRhMGViYjYyNWQ1ZTk1MGNhNGE0" />
        <div class="marquee_text">在海大的四年，连同大家一起，都深深刻在我的心底</div>
        <img class="marquee_pic" width="500"  src="https://ikinhuang.oss-cn-shanghai.aliyuncs.com/5.jpeg?versionId=CAEQDRiBgMDy1eLWyxciIGE1MjUyMTQxMTFlZDRlZGRhYjMyNmE1MjYwZjk2ZTE5" />
        <div class="marquee_text">感谢大家一路的陪伴，不管未来怎么样，努力向前奔跑吧</div>
        <img class="marquee_pic" width="600"  src="https://ikinhuang.oss-cn-shanghai.aliyuncs.com/6.jpeg?versionId=CAEQDRiBgICb2OLWyxciIDg1OWNhYzQ4MDJjYjQ4YzVhOTZhZjZiZmYyNTAwZTc1" />
        <div class="marquee_end">夏天来了，毕业快乐</div>
        <img class="marquee_pic" width="700"  src="https://ikinhuang.oss-cn-shanghai.aliyuncs.com/7.jpeg?versionId=CAEQDRiBgICJmaTAzhciIDJlODYyZDM0YmM3YzRkMmE5MTg4NDNkOGJlYzcxMzY3" />
      </div>
    </marquee>
    <audio id="music" loop src="https://ikinhuang.oss-cn-shanghai.aliyuncs.com/a.mp3?versionId=CAEQDRiBgIDf4JTsyRciIGE0NTM4ZWE3MGI4ZDQ5MThhMTM5N2I2MWMzNDNmMmZk">
    </audio>
  </div>
</template>

<script>
export default {
  name: "TheEnd",
  data() {
    return {
      start: false,
    }
  },
  methods: {
    startPlay: function () {
      var music = document.getElementById("music");
      music.play();
      this.start = true;
      var marquee = document.getElementById("marquee");
      marquee.start();
    },
    closeTheEnd: function () {
      this.$router.push("/center");
    }
  },
  mounted() {
    var marquee = document.getElementById("marquee");
    marquee.stop();
  }
}
</script>

<style scoped>
.the-end-of-my-college {
  background-color: #000;
  width: 100vw;
  height: 100vh;
}

.close_btn {
  color: #EBECF0;
  font-size: 30px;
  position: absolute;
  right: 10px;
}

.start-pic {
  margin-top: 40vh;
}

#marquee {
  height: 100vh;
  z-index: 1000;
}

.text {
  text-align: center;
}

.marquee_title {
  font-size: 40px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.marquee_text {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.marquee_pic {
  margin-top: 20px;
  margin-bottom: 20px;
}

.marquee_end {
  font-size: 40px;
  margin-top: 40px;
}
</style>
