<template>
  <div id="password-manage">

    <div class="user-info-box">
      <el-row>
        <el-col :span="12">
          <el-form class="el-user-form" ref="userForm" :model="personalInfo" label-width="90px">

            <el-form-item label="ID" prop="userId">
              <el-input v-model="personalInfo.userId" :disabled="true"></el-input>
            </el-form-item>

            <el-form-item label="姓名" prop="userName">
              <el-input v-model="personalInfo.userName" :disabled="true"></el-input>
            </el-form-item>

            <el-form-item label="身份" prop="identity">
              <el-input v-model="personalInfo.identity" :disabled="true"></el-input>
            </el-form-item>

            <el-form-item label="注册时间" prop="createTime">
              <el-input v-model="personalInfo.createTime" :disabled="true"></el-input>
            </el-form-item>

          </el-form>
        </el-col>

        <el-col :span="12">
          <el-form class="el-user-form" ref="userForm" :model="personalInfo" label-width="90px">

            <el-form-item label="新密码" prop="password">
              <el-input type="password" v-model="personalInfo.password" :disabled="false"></el-input>
            </el-form-item>

            <el-form-item label="确认密码" prop="passwordConfirm">
              <el-input type="password" v-model="personalInfo.passwordConfirm" :disabled="false"></el-input>
            </el-form-item>

            <el-form-item id="el-detail-form-button">
              <el-button class="el-detail-form-button" type="primary" @click="submitChange">修改密码</el-button><br>
            </el-form-item>

          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordManage",
  data() {
    return {
      personalInfo: {
        id: "",
        userId: "",
        userName: "",
        identity: "",
        createTime: "",
        updateTime: "",
        password: "",
        passwordConfirm: ""
      },
    }
  },
  methods: {
    submitChange: function () {
      var url = "https://api2.ikin.top/user/password/update";
      if(this.personalInfo.password !== this.personalInfo.passwordConfirm) {
        this.$message({
          message: '请确认两次密码输入一致',
          type: 'warning',
          center: 'true'
        });
      }else {
        this.$axios({
          method: 'post',
          url: url,
          params: {
            userId: sessionStorage.getItem("userId"),
            token: sessionStorage.getItem("token")
          },
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: JSON.stringify({
            password: this.$md5(this.personalInfo.password)
          })
        }).then((response) => {
          if (response.data.data.isSuccess) {
            this.$message({
              message: '密码修改成功，请牢记新密码！',
              type: 'success',
              center: 'true'
            });
          }else if(response.data.code === 301 || response.data.code === 302){
            this.$message({
              message: '用户信息失效',
              type: 'warning',
              center: 'true'
            });
            sessionStorage.setItem("isLogin", 0);
            this.$router.push("/login");
          }  else {
            this.$message({
              message: '密码修改失败',
              type: 'warning',
              center: 'true'
            })
          }
        }).catch((error) => {
          this.$message({
            message: '服务器错误',
            type: 'error',
            center: 'true'
          });
          console.error(error);
        });
      }
    }
  },
  mounted() {
    var url = "https://api2.ikin.top/user/detail";
    this.$axios({
      method: 'get',
      url: url,
      params: {
        userId: sessionStorage.getItem("userId"),
        token: sessionStorage.getItem("token")
      }
    }).then((response) => {
      //console.log(response.data);
      if (response.data.success) {
        this.personalInfo.id = response.data.data.id;
        this.personalInfo.userId = response.data.data.userId;
        this.personalInfo.userName = response.data.data.userName;
        if(response.data.data.identity === 1) {
          this.personalInfo.identity = "管理员";
        }else {
          this.personalInfo.identity = "普通用户";
        }
        this.personalInfo.createTime = response.data.data.createTime;
        this.personalInfo.updateTime = response.data.data.updateTime;
      }else if(response.data.code === 301 || response.data.code === 302){
        this.$message({
          message: '用户信息失效',
          type: 'warning',
          center: 'true'
        });
        sessionStorage.setItem("isLogin", 0);
        this.$router.push("/login");
      } else {
        this.$message({
          message: '拉取用户详情失败',
          type: 'warning',
          center: 'true'
        });
        this.inDetail = 0;
      }
    }).catch((error) => {
      console.log(error);
      this.$message({
        message: '服务器错误',
        type: 'error',
        center: 'true'
      });
    });
  }
}
</script>

<style scoped>
.user-info-box {
  margin-top: 5vh;
}
</style>
