<template>
  <div id="new-ship-result">
    <div class="wrapper" v-show="loading">
      <div class="rubik-loader"></div>
      <div class="loading-text">正在计算数据...</div>
    </div>

    <div class="new-ship-result" v-show="!loading">
      <el-table stripe class="new-ship-table" :data="newShip" max-height="400">
        <el-table-column prop="key" label="属性名" width="200"></el-table-column>
        <el-table-column prop="value" label="属性值" width="300">
          <template slot-scope="scope">
            {{ scope.row.value | rounding}}
          </template>
        </el-table-column>
      </el-table>

      <el-button class="next-step-btn" type="primary" round @click="nextStep">下一步</el-button>

    </div>

  </div>
</template>

<script>
export default {
  name: "NewShipResult",
  props: ['result'],
  data() {
    return {
      loading: true,
      newShip: [
        {
          key: "船名",
          value: this.result.shipName
        },
        {
          key: "类型",
          value: this.result.shipType
        },
        {
          key: "国家",
          value: this.result.shipCountry
        },
        {
          key: "吨位 (t)",
          value: this.result.tonnage
        },
        {
          key: "船体自重 (t)",
          value: this.result.hullWeight
        },
        {
          key: "排水量 (t)",
          value: this.result.displacement
        },
        {
          key: "船长 (m)",
          value: this.result.length
        },
        {
          key: "船宽 (m)",
          value: this.result.width
        },
        {
          key: "船深 (m)",
          value: this.result.depth
        },
        {
          key: "垂线间长 (m)",
          value: this.result.lengthBetweenPerpendiculars
        },
        {
          key: "吃水 (m)",
          value: this.result.draft
        },
        {
          key: "自持力 (nmile)",
          value: this.result.endurance
        },
        {
          key: "油耗 (t/d)",
          value: this.result.fuelConsumption
        },
        {
          key: "主机功率 (kW)",
          value: this.result.monitorPowerSMCR
        },
        {
          key: "载员 (人)",
          value: this.result.crew
        },
        {
          key: "载客 (人)",
          value: this.result.passenger
        },
        {
          key: "横稳性半径 (m)",
          value: this.result.metacentricRadius
        },
        {
          key: "重心高度 (m)",
          value: this.result.metacentricHeight
        },
        {
          key: "浮心高度 (m)",
          value: this.result.floatingHeight
        },
        {
          key: "设计航速 (kn)",
          value: this.result.designSpeed
        },
        {
          key: "方形系数",
          value: this.result.blockCoefficient
        },
        {
          key: "棱形系数",
          value: this.result.prismaticCoefficient
        },
        {
          key: "水线面系数",
          value: this.result.waterlineCoefficient
        },
        {
          key: "中横剖面系数",
          value: this.result.midshipSectionCoefficient
        }
      ]
    }
  },
  methods: {
    nextStep: function () {
      this.$emit('nextStep');
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 8000);
  },
  filters: {
    rounding (value) {
      if(typeof(value) === "number" || value === 0) { //這個判斷是為了防止數據為空，頁面渲染為NAN
        return Number(value).toFixed(4);
      }else {
        return value;
      }
    }
  }
}
</script>

<style lang="scss">

.wrapper {
  position: relative;
  margin-top: 20vh;
}
.rubik-loader{
  width: 64px;
  height: 64px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-image: url(https://www.ikin.top/pic/loading.gif);
  background-repeat: no-repeat;
  background-position: center;
}

#new-ship-result {
  align-items: center;
}

.loading-text {
  padding-top: 10vh;
  position: relative;
}

.new-ship-result {
  position: absolute;
  top: 3vh;
  left: 18vw;
}

.new-ship-table {
  width: 500px;
}

.next-step-btn {
  position: relative;
  bottom: -10px;
  right: -40vh;
}

</style>
