import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from "@/components/Login/Login";
import Welcome from "@/components/Welcome/Welcome";
import DesignCenter from "@/components/DesignCenter/DesignCenter";
import UserCenter from "@/components/UserCenter/UserCenter";
import Center from "@/components/Center/Center";
import TheEnd from "@/components/TheEnd/TheEnd";
import Mobile from "@/components/Mobile/Mobile";

Vue.use(VueRouter);
const router = new VueRouter({

    routes: [
        {
            path: '/',
            redirect: 'Login'
        },
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/mobile',
            name: 'Mobile',
            component: Mobile
        },
        {
            path: '/welcome',
            name: 'Welcome',
            component: Welcome
        },
        {
            path: '/center',
            component: Center,
            children: [
                {
                    path: '/',
                    redirect: 'DesignCenter'
                },
                {
                    path: 'design',
                    name: 'DesignCenter',
                    component: DesignCenter
                },
                {
                    path: 'user',
                    name: 'UserCenter',
                    component: UserCenter
                }
            ]
        },
        {
            path: '/theEnd',
            name: 'TheEnd',
            component: TheEnd
        }

    ]
});

router.beforeEach((to, from, next) => {
    if (to.name === 'Mobile' ) {
            next();
    }else if (to.name !== 'Login' && sessionStorage.getItem("isLogin") !== '1') {
        next({name: 'Login'});
    }else if(to.name === 'Login' && sessionStorage.getItem("isLogin") === '1') {
        next({name: 'DesignCenter'});
    }else if (to.matched.length === 0) {  //如果未匹配到路由
        next('/');
    }else {
        next();
    }
});

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location, onResolve, onReject) {

    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)

    return originalPush.call(this, location).catch(err => err)

}

export default router;



