<template>
  <div id="new-ship-info">
    <el-row class="new-ship-info-box">
      <el-col :span="12">
        <div>
          <el-form :model="newShip" class="new-ship-form" ref="newShipForm" label-width="130px">

            <el-form-item label="船名">
              <el-input class="parent-ship-input" v-model="newShip.shipName" placeholder="船名"></el-input>
            </el-form-item>
            <el-form-item label="国家">
              <el-select v-model="newShip.shipCountry" placeholder="请选择国家">
                <el-option label="中国 🇨🇳" value="CHINA"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="自持力（nmile）">
              <el-input class="parent-ship-input" v-model="newShip.endurance" placeholder="自持力"></el-input>
            </el-form-item>
            <el-form-item label="航速（kn）">
              <el-input class="parent-ship-input" v-model="newShip.designSpeed" placeholder="航速"></el-input>
            </el-form-item>
            <el-form-item label="载客（人）">
            <el-input class="parent-ship-input" v-model="newShip.passenger" placeholder="载客"></el-input>
          </el-form-item>
          </el-form>
        </div>
      </el-col>

      <el-col :span="12">
        <div>
          <el-form :model="newShip" class="new-ship-form" ref="newShipForm" label-width="130px">

            <el-form-item label="类型">
              <el-select v-model="newShip.shipType" placeholder="请选择新船类型">
                <el-option label="散货船" value="BULK"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="吨位（DWT）">
              <el-input class="parent-ship-input" v-model="newShip.tonnage" placeholder="吨位"></el-input>
            </el-form-item>
            <el-form-item label="油耗（t/d）">
              <el-input class="parent-ship-input" v-model="newShip.fuelConsumption" placeholder="油耗"></el-input>
            </el-form-item>
            <el-form-item label="载员（人）">
              <el-input class="parent-ship-input" v-model="newShip.crew" placeholder="载员"></el-input>
            </el-form-item>
            <el-form-item label="是否巴拿马散货船">
              <el-switch v-model="newShip.isPanama"></el-switch>
            </el-form-item>

            <el-button type="primary" round @click="submitNewShip">下一步</el-button>
          </el-form>
        </div>
      </el-col>

    </el-row>
  </div>
</template>

<script>
export default {
  name: "NewShipInfo",
  data() {
    return {
      newShip: {
        shipName: "",
        shipType: "",
        shipCountry: "",
        tonnage: "",
        endurance: "",
        fuelConsumption: "",
        designSpeed: "",
        crew: "",
        passenger: "",
        isPanama: false
      },
      newShipResult: {}
    }
  },
  methods: {
    submitNewShip: function () {
      var url = "https://api2.ikin.top/ship/design/new/data";
      if(this.newShip.shipName === "" || this.newShip.shipType === "" || this.newShip.shipCountry === ""
       || this.newShip.tonnage === "" || this.newShip.endurance === "" ||this.newShip.fuelConsumption === ""
       ||   this.newShip.designSpeed === "" || this.newShip.crew === "" || this.newShip.passenger === "" ){
        this.$message({
          message: '参数缺失',
          type: 'warning',
          center: 'true'
        });
      }else {
          this.$axios({
            method: 'post',
            url: url,
            params: {
              userId: sessionStorage.getItem("userId"),
              token: sessionStorage.getItem("token")
            },
            headers: {
              'Content-Type': 'application/json;charset=UTF-8'
            },
            data: JSON.stringify({
              shipName: this.newShip.shipName,
              shipType: this.newShip.shipType,
              shipCountry: this.newShip.shipCountry,
              tonnage: this.newShip.tonnage,
              endurance: this.newShip.endurance,
              fuelConsumption: this.newShip.fuelConsumption,
              designSpeed: this.newShip.designSpeed,
              crew: this.newShip.crew,
              passenger: this.newShip.passenger,
              isPanama: this.newShip.isPanama
            })
          }).then((response) => {
            if (response.data.success) {
              this.$message({
                message: '提交信息成功',
                type: 'success',
                center: 'true'
              });
              this.newShipResult = response.data.data;
              this.$emit('newStepWithData', this.newShipResult);
            }else if(response.data.code === 301 || response.data.code === 302){
              this.$message({
                message: '用户信息失效',
                type: 'warning',
                center: 'true'
              });
              sessionStorage.setItem("isLogin", 0);
              this.$router.push("/login");
            }else if(response.data.code === 601 || response.data.code === 602 || response.data.code === 603 || response.data.code === 604 || response.data.code === 605){
              this.$message({
                message: response.data.msg,
                type: 'warning',
                center: 'true'
              });
            } else {
              this.$message({
                message: '提交信息失败',
                type: 'warning',
                center: 'true'
              });
            }
          }).catch((error) => {
            this.$message({
              message: '服务器错误',
              type: 'error',
              center: 'true'
            });
            console.error(error);
          });
        }
    }
  }
}
</script>

<style scoped>
#new-ship-info {
  margin-top: 50px;
}

.el-form-item  {
  margin: 5px;
}

</style>
