<template>
  <div id="center">
    <DesignCenter v-show="centerToggle" @CenterSwitch="CenterSwitch" />
    <UserCenter v-show="!centerToggle"  @CenterSwitch="CenterSwitch" />
  </div>
</template>

<script>
import DesignCenter from "@/components/DesignCenter/DesignCenter";
import UserCenter from "@/components/UserCenter/UserCenter";

export default {
  name: "Center",
  components: {
    DesignCenter,
    UserCenter
  },
  data() {
    return {
      centerToggle: true
    }
  },
  methods: {
    CenterSwitch: function () {
      this.centerToggle = !this.centerToggle;
    }
  }
}
</script>

<style scoped>

.user-center-box {
  position: absolute;
  right: 15px;
  top: 10px;

}

.user-center-btn {
  width: 50px;
  height: 50px;
}

.el-icon-user-solid {
  font-size: 20px;
}

</style>
