<template>
  <div id="user-manage">
    <el-row class="function-tool-bar">
      <el-col :span="10">

        <el-table class="user-table" :data="users" height="400">
          <el-table-column prop="id" label="ID" width="50">
          </el-table-column>
          <el-table-column prop="userId" label="编号" width="80">
          </el-table-column>
          <el-table-column prop="userName" label="姓名" width="130">
          </el-table-column>
          <el-table-column label="操作" width="130">
            <template slot-scope="scope">
              <el-button @click="handleClick(scope.row.id)" size="mini">查看</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-button class="el-form-button" v-if="addFunction === 0" type="success" @click="addUserSwitch">新增用户</el-button>
        <el-button class="el-form-button" v-if="addFunction === 1" type="info" @click="addUserSwitch">管理用户</el-button>

      </el-col>

      <el-col :span="12" >
        <div v-if="addFunction === 0">
          <el-form class="user-form" ref="userForm" :model="selectedUser" label-width="80px">

            <el-form-item label="编号" prop="userId">
              <el-input v-model="selectedUser.userId" :disabled="true"></el-input>
            </el-form-item>

            <el-form-item label="姓名" prop="userName">
              <el-input v-model="selectedUser.userName" :disabled="false"></el-input>
            </el-form-item>

            <el-form-item label="身份" prop="identity">
              <el-select v-model="selectedUser.identityName" placeholder="身份" style="width: 240px">
                <el-option label="普通用户" value="0"></el-option>
                <el-option label="管理员" value="1"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="注册时间" prop="createTime">
              <el-input v-model="selectedUser.createTime" :disabled="true"></el-input>
            </el-form-item>

            <el-form-item>
              <el-button class="el-detail-form-button" type="primary" @click="submitChange">提交修改</el-button>
              <el-button class="el-detail-form-button" type="danger" @click="visible = true">移除用户</el-button>
              <el-popover placement="top" width="160" v-model="visible">
                <p>确定删除吗？</p>
                <div style="text-align: right; margin: 0">
                  <el-button type="primary" size="mini" @click="visible = false">取消</el-button>
                  <el-button type="danger" size="mini" @click="deleteUser">确定</el-button>
                </div>
              </el-popover>
            </el-form-item>
          </el-form>
        </div>

        <div v-if="addFunction === 1">
          <el-form class="user-form" ref="userForm" :model="newUser" label-width="80px">

            <el-form-item label="编号" prop="userId">
              <el-input v-model="newUser.userId"></el-input>
            </el-form-item>

            <el-form-item label="密码" prop="password">
              <el-input type="password" v-model="newUser.password"></el-input>
            </el-form-item>

            <el-form-item label="确认密码" prop="passwordConfirm">
              <el-input type="password" v-model="newUser.passwordConfirm"></el-input>
            </el-form-item>

            <el-form-item label="姓名" prop="userName">
              <el-input v-model="newUser.userName"></el-input>
            </el-form-item>

            <el-form-item label="身份" prop="identity">
              <el-select v-model="newUser.identity" placeholder="身份" style="width: 240px">
                <el-option label="普通用户" value="0"></el-option>
                <el-option label="管理员" value="1"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button class="el-detail-form-button" type="success" @click="signInUser">注册用户</el-button>
            </el-form-item>
          </el-form>
        </div>

      </el-col>

    </el-row>

  </div>
</template>

<script>
export default {
  name: "UserManage",
  data() {
    return {
      visible: false,
      users: [],
      user: {
        id: "",
        userId: "",
        userName: "",
        identity: "",
        createTime: "",
        updateTime: ""
      },
      selectedUser: {
        id: "",
        userId: "",
        userName: "",
        identity: "",
        identityName: "",
        createTime: "",
        updateTime: ""
      },
      newUser: {
        id: "",
        userId: "",
        password: "",
        passwordConfirm: "",
        userName: "",
        identity: ""
      },
      addFunction: 0
    }
  },
  methods: {
    handleClick:function (id) {
      var url = "https://api2.ikin.top/user/query/user/" + id;
      this.$axios({
        method: 'get',
        url: url,
        params: {
          userId: sessionStorage.getItem("userId"),
          token: sessionStorage.getItem("token")
        }
      }).then((response) => {
        if (response.data.success) {
          this.selectedUser.id = response.data.data.id;
          this.selectedUser.userId = response.data.data.userId;
          this.selectedUser.userName = response.data.data.userName;
          if(response.data.data.identity === 1) {
            this.selectedUser.identityName = "管理员";
            this.selectedUser.identity = 1;
          }else {
            this.selectedUser.identityName = "普通用户";
            this.selectedUser.identity = 0;
          }
          this.selectedUser.createTime = response.data.data.createTime;
          this.selectedUser.updateTime = response.data.data.updateTime;
        } else {
          this.$message({
            message: '拉取用户详情失败',
            type: 'warning',
            center: 'true'
          });
          this.inDetail = 0;
        }
      }).catch((error) => {
        console.log(error);
        this.$message({
          message: '服务器错误',
          type: 'error',
          center: 'true'
        });
      });
    },
    submitChange: function () {
      if(this.selectedUser.identityName === "0") {
        this.selectedUser.identity = 0;
      }else if(this.selectedUser.identityName === "1") {
        this.selectedUser.identity = 1;
      }else if(this.selectedUser.identityName === "管理员") {
        this.selectedUser.identity = 1;
      }else if(this.selectedUser.identityName === "普通用户") {
        this.selectedUser.identity = 0;
      }
      var url = "https://api2.ikin.top/user/update";
      this.$axios({
        method: 'post',
        url: url,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
        params: {
          userId: sessionStorage.getItem("userId"),
          token: sessionStorage.getItem("token")
        },
        data: JSON.stringify({
          userId: this.selectedUser.userId,
          userName: this.selectedUser.userName,
          identity: this.selectedUser.identity
        })
      }).then((response) => {
        if (response.data.data.isSuccess) {
          this.$message({
            message: '修改成功',
            type: 'success',
            center: 'true'
          })
          for (var i = 0; i < this.users.length; i++) {
            if (this.users[i].id === this.selectedUser.id) {
              this.users[i].userName = this.selectedUser.userName;
            }
          }
        }else if(response.data.code === 301 || response.data.code === 302){
          this.$message({
            message: '用户信息失效',
            type: 'warning',
            center: 'true'
          });
          sessionStorage.setItem("isLogin", 0);
          this.$router.push("/login");
        } else {
          this.$message({
            message: '修改失败',
            type: 'warning',
            center: 'true'
          })
        }
      }).catch((error) => {
        console.log(error);
        this.$message({
          message: '服务器错误',
          type: 'error',
          center: 'true'
        });
      });
    },
    deleteUser: function () {
      var url = "https://api2.ikin.top/user/delete";
      this.$axios({
        method: 'post',
        url: url,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
        params: {
          userId: sessionStorage.getItem("userId"),
          token: sessionStorage.getItem("token")
        },
        data: JSON.stringify({
          userId: this.selectedUser.userId,
        })
      }).then((response) => {
        if (response.data.data.isSuccess) {
          this.$message({
            message: '删除成功',
            type: 'success',
            center: 'true'
          });
          this.visible = false;
          for (var i = 0; i < this.users.length; i++) {
            if (this.users[i].id === this.selectedUser.id) {
              this.users.splice(i, 1);
            }
          }
          this.selectedUser.userId = "";
          this.selectedUser.userName = "";
          this.selectedUser.identity = "";
          this.selectedUser.createTime = "";
          this.inDetail = 0;
        }else if(response.data.code === 301 || response.data.code === 302){
          this.$message({
            message: '用户信息失效',
            type: 'warning',
            center: 'true'
          });
          sessionStorage.setItem("isLogin", 0);
          this.$router.push("/login");
        } else {
          this.$message({
            message: '删除失败',
            type: 'warning',
            center: 'true'
          })
        }
      }).catch((error) => {
        console.log(error);
        this.$message({
          message: '服务器错误',
          type: 'error',
          center: 'true'
        });
      });
    },
    addUserSwitch: function (){
      if(this.addFunction === 1) {
        this.addFunction = 0;
      }else {
        this.addFunction = 1;
      }
    },
    signInUser: function () {
      var url = "https://api2.ikin.top/user/add";
      if(this.newUser.password !== this.newUser.passwordConfirm) {
        this.$message({
          message: '两次密码输入不一致',
          type: 'warning',
          center: 'true'
        });
      }else if(this.newUser.identity === "" || this.newUser.userName === "" || this.newUser.userId === ""
          || this.newUser.password === "" || this.newUser.passwordConfirm === ""){
        this.$message({
          message: '缺少必填项目',
          type: 'warning',
          center: 'true'
        });
      } else {
        this.$axios({
          method: 'post',
          url: url,
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          },
          params: {
            userId: sessionStorage.getItem("userId"),
            token: sessionStorage.getItem("token")
          },
          data: JSON.stringify({
            userId: this.newUser.userId,
            userName: this.newUser.userName,
            password: this.$md5(this.newUser.password),
            identity: this.newUser.identity
          })
        }).then((response) => {
          if (response.data.data.isSuccess) {
            this.$message({
              message: '新增用户成功',
              type: 'success',
              center: 'true'
            });
            this.newUser.id = response.data.data.additionalData;
            this.users.push(this.newUser);
          }else if(response.data.code === 301 || response.data.code === 302){
            this.$message({
              message: '用户信息失效',
              type: 'warning',
              center: 'true'
            });
            sessionStorage.setItem("isLogin", 0);
            this.$router.push("/login");
          } else {
            this.$message({
              message: '新增用户失败',
              type: 'warning',
              center: 'true'
            });
          }
        }).catch((error) => {
          console.log(error);
          this.$message({
            message: '服务器错误',
            type: 'error',
            center: 'true'
          });
        });
      }
    }
  },
  mounted() {
    var url = "https://api2.ikin.top/user/query/list";
    this.$axios({
      method: 'get',
      url: url,
      params: {
        userId: sessionStorage.getItem("userId"),
        token: sessionStorage.getItem("token")
      }
    }).then((response) => {
      if (response.data.success) {
        this.users = response.data.data;
      }else if(response.data.code === 301 || response.data.code === 302){
        this.$message({
          message: '用户信息失效',
          type: 'warning',
          center: 'true'
        });
        sessionStorage.setItem("isLogin", 0);
        this.$router.push("/login");
      } else {
        this.$message({
          message: '用户列表拉取失败',
          type: 'warning',
          center: 'true'
        })
      }
    }).catch((error) => {
      console.log(error);
      this.$message({
        message: '服务器错误',
        type: 'error',
        center: 'true'
      });
    });
  }

}
</script>

<style scoped>
.user-table {
  margin-top: 5vh;
  margin-left: 8vw;
}

.user-form {
  margin-top: 5vh;
  margin-left: 10vw;
}
</style>
