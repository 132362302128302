<template>
  <div class="login">
    <div class="login-box">
      <form class="login" :model="user">
        <div class="login-segment">
          <img class="login-logo" src="../../assets/logo.png" />
          <h1 class="login-text-center">船舶设计一点通</h1>
        </div>

        <label>
          <input type="text" class="login-input-box" id="userId" v-model="user.userId" placeholder="用户ID" autocomplete="off" @keyup.enter="login" />
        </label>
        <label>
          <input type="password" class="login-input-box" id="password" v-model="user.password" placeholder="密码" @keyup.enter="login" />
        </label>

        <div class="login-segment">
          <button class="login-button" type="button" @click="login">
            <i class="icon ion-chevron-right"></i>
          </button>
        </div>
      </form>

    </div>
  </div>
</template>

<script>

export default {
  name: "Login",
  data(){
    return {
      user: {
        userId: "",
        password: ""
      }
    }
  },
  methods: {
    login: function (){
      var url = "https://api2.ikin.top/user/login";
      if(this.user.userId === "") {
        this.$message({
          message: '用户名或密码不能为空',
          type: 'warning',
          center: 'true'
        });
      }else if(this.user.password === "") {
        this.$message({
          message: '用户名或密码不能为空',
          type: 'warning',
          center: 'true'
        });
      }else {
        this.$axios({
          method: 'post',
          url: url,
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: JSON.stringify({
            userId: this.user.userId,
            password: this.$md5(this.user.password)
          })
        }).then((response) => {
          if (response.data.data.isSuccess) {
            sessionStorage.clear();
            sessionStorage.setItem("isLogin", 1);
            sessionStorage.setItem("userId", JSON.stringify(response.data.data.additionalData.userId).split("\"")[1])
            sessionStorage.setItem("userName", JSON.stringify(response.data.data.additionalData.userName).split("\"")[1]);
            sessionStorage.setItem("token", JSON.stringify(response.data.data.additionalData.token).split("\"")[1]);
            this.$message({
              message: '登陆成功',
              type: 'success',
              center: 'true'
            });
            //跳转逻辑
            this.$router.push("/welcome");

          } else {
            this.$message({
              message: '用户名或密码错误',
              type: 'warning',
              center: 'true'
            });
          }
        }).catch((error) => {
          this.$message({
            message: '服务器错误',
            type: 'error',
            center: 'true'
          });
          console.error(error);
        });
      }
    },
    mobileDetect: function (){
      //针对移动设备进行屏蔽
      var sUserAgent = navigator.userAgent.toLowerCase();

      var bIsIphone = sUserAgent.match(/iphone os/i) == 'iphone os';
      var bIsMidp = sUserAgent.match(/midp/i) == 'midp';
      var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == 'rv:1.2.3.4';
      var bIsUc = sUserAgent.match(/ucweb/i) == 'web';
      var bIsCE = sUserAgent.match(/windows ce/i) == 'windows ce';
      var bIsWM = sUserAgent.match(/windows mobile/i) == 'windows mobile';
      var bIsAndroid = sUserAgent.match(/android/i) == 'android';

      if(bIsIphone || bIsMidp || bIsUc7 || bIsUc || bIsCE || bIsWM || bIsAndroid ){
        this.$router.push({name: 'Mobile'});
      }
    }
  },
  mounted() {
    this.mobileDetect();
  }
}
</script>

<style lang="scss">

@import "../../assets/style/style";

body, html {
  background-color: $color-bg;
}

body, p, input, select, textarea, button {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: -0.2px;
  font-size: $ruler;
}

div, p {
  /*color: $color-shadow;*/
  text-shadow: 1px 1px 1px $color-white;
}


form {
  padding: $ruler;
  width: $ruler*20;
  margin: 0 auto;
}

.login-text-center {
  color: $text-color;
}

.login-segment {
  padding: $ruler*2 0;
  text-align: center;
}

.login-logo {
  width: 80px;
  height: 80px;
}

button, input {
  border: 0;
  outline: 0;
  font-size: $ruler;
  border-radius: $ruler*20;
  padding: $ruler;
  background-color:$color-bg;
/*
  text-shadow: 1px 1px 0 $color-white;
*/
}

label {
  display: block;
  margin-bottom: $ruler*1.5;
  width: 100%;
}

input {
  margin-right: $ruler/2;
  box-shadow:  inset 2px 2px 5px $color-shadow, inset -5px -5px 10px $color-white;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  appearance: none;
  -webkit-appearance: none;

  &:focus {
    box-shadow:  inset 1px 1px 2px $color-shadow, inset -1px -1px 2px $color-white;
  }
}

button {
  color: #61677C;
  box-shadow: -5px -5px 20px $color-white,  5px 5px 20px $color-shadow;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    box-shadow: -2px -2px 5px $color-white, 2px 2px 5px $color-shadow;
  }

  &:active {
    box-shadow: inset 1px 1px 2px $color-shadow, inset -1px -1px 2px $color-white;
  }

  .icon {
    margin-right: $ruler/2;
    color: $text-color;
  }

  &.login-button {
    border-radius: $ruler/2;
    line-height: 0;
    width: $ruler*3;
    height: $ruler*3;
    display:inline-flex;
    justify-content: center;
    align-items:center;
    margin: 0 $ruler/2;
    font-size: $ruler*1.2;

    .icon {
      margin-right: 0;
    }
  }

  &.red {
    display: block;
    width: 100%;
    color:$text-color;
  }
}

.input-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  label {
    margin: 0;
    flex: 1;
  }
}

.login-input-box {
  color: $text-color;
}

input::placeholder{
  color: $default-text-color;
}


.login-box {
  width: 35vw;
  color: #61677C;
  box-shadow: -5px -5px 20px $color-white,  5px 5px 20px $color-shadow;
  font-weight: 600;
  border-radius: $ruler*2;
  margin: 0 auto;
  position: relative;
  top: 15vh;
}


</style>
