<template>
  <div id="about">
    <div class="about-container">

      <div class="about-information">
        <img src="../../assets/ikin.jpg" class="el-author-logo" @click="bonus"/>
        <p class="el-about-text el-about-bold-text">船舶设计一点通</p>
        <p class="el-about-append-text">毕业设计作品</p>
        <p class="el-about-text">Ver. 1.1</p>
        <p class="el-about-text">Designed by <a href="https://www.ikin.top">iKin Huang</a></p>
        <p class="el-about-copyright-text">2019-2021 ©️ ikin.top 版权所有</p>
      </div>

      <img src="../../assets/lighthouse.svg" class="light-house" style="height: 300px">
      <img src="../../assets/bg.png" class="background" style="height: 300px; width: 60vw">
      <img src="../../assets/bg2.png" class="background2" style="height: 300px; width: 60vw">


    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      i: 0,
      times: 0
    }
  },
  methods: {
    bonus: function () {
      this.times += 1;
      if(this.times >= 3) {
        this.times = 0;
        this.i += 1;
        if(this.i === 1) {
          alert("嘻嘻(´･ω･`)");
        }else if(this.i === 2) {
          alert("居然被你发现了呀(=ﾟωﾟ)ﾉ");
        }else if(this.i === 3) {
          alert("这里有个小彩蛋哦( ´ ▽ ` )ﾉ");
        }else if(this.i === 4) {
          this.$router.push("/theEnd");
        }
      }

    }
  }
}
</script>

<style lang="scss">

.about-container {
  margin-top: 2vh;
}

.about-information {
  position: relative;
  z-index: 100;
}

.el-author-logo {
  width: 120px;
  height: 120px;
  border-radius: 150px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.el-about-append-text {
  font-size: 13px;
  color: #999999;
  margin-top: 0px;
}

.el-about-text {
  margin-top: 3px;
  margin-bottom: 8px;
}

.el-about-bold-text {
  font-weight: bolder;
  font-size: 20px;
}

.el-about-copyright-text {
  margin-top: 20px;
  padding-bottom: 5px;
  font-size: 12px;
  color: #999999;
}

.light-house {
  position: absolute;
  left: 10px;
  bottom: 0px;
  z-index: 10;
}

.background {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  border-radius: 20px;
}

.background2 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  border-radius: 20px;
}

a {
  text-decoration: none;
  color: #0e54b8;
}
</style>
